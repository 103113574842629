<template>
  <div class="app-container">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="海外调拨补货" name="1" />
      <el-tab-pane label="国内快船补货" name="5" />
      <el-tab-pane label="国内普船补货" name="2" />
    </el-tabs>

    <el-form
      ref="ruleForm"
      :model="form"
      :rules="rules"
      label-width="134px"
      style="display: flex; flex-wrap: wrap; margin-top: 15px"
    >
      <div class="el-lt" style="width: 97%">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item
              label="销售预测版本"
              prop="versionCode"
              :class="$i18n.locale"
            >
              <el-select
                v-model="form.versionCode"
                class="style_w100"
                filterable
                :placeholder="$t('page.selectPlaceholder')"
                @change="changeVersion"
              >
                <el-option
                  v-for="item in dsbVersionOptions"
                  :key="item.versionCode"
                  :label="item.versionCode"
                  :value="item.versionCode"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="备货渠道" :class="$i18n.locale">
              <el-select
                v-model="form.stockChannelIdList"
                class="style_w100"
                clearable
                filterable
                multiple
                collapse-tags
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in StockChannelOptions"
                  :key="item.channelId"
                  :label="item.channelName"
                  :value="item.channelId"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Style" :class="$i18n.locale">
              <el-select
                v-model="form.styleIdList"
                oninput="(/\s+/g, '')"
                value-key="id"
                clearable
                filterable
                class="style_w100"
                @change="handleStyleChange"
              >
                <el-option
                  v-for="item in styleOptions"
                  :key="item.id"
                  :label="item.styleName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Color">
              <el-select
                v-model="form.colorList"
                class="style_w100"
                clearable
                multiple
                collapse-tags

                filterable
              >
                <el-option
                  v-for="item in colorOptions"
                  :key="item.colorName"
                  :label="item.colorName"
                  :value="item.colorName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Size">
              <el-select
                v-model="form.sizeList"
                class="style_w100"
                clearable
                filterable
                multiple
                collapse-tags
              >
                <el-option
                  v-for="(item, index) in sizeOptions"
                  :key="index"
                  :label="item.size"
                  :value="item.size"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="季节" :class="$i18n.locale">
              <el-select
                v-model="form.seasonIdList"
                class="style_w100"
                clearable
                filterable
                multiple
                collapse-tags

                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in _getAllCommodityDict('SEASON')"
                  :key="item.val"
                  :label="item.label"
                  :value="item.val"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="定位" :class="$i18n.locale">
              <Select
                v-model="form.positionIdList"
                style="width:100%;"
                api-key="position"
                clearable
                filterable
                collapse-tags
                multiple
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="品牌" :class="$i18n.locale">
              <el-select
                v-model="form.brandIdList"
                class="style_w100"
                clearable
                filterable
                collapse-tags
                multiple
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in brandOptions"
                  :key="item.id"
                  :label="item.brandName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Sku" :class="$i18n.locale">
              <el-input
                v-model="form.skuList"
                placeholder="多个以换行符分隔"
                type="textarea"
                autosize
                resize="none"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="供应商" :class="$i18n.locale">
              <el-select
                v-model="form.vendorIdList"
                multiple
                collapse-tags
                filterable
                clearable
                style="width:100%;"
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in vendorOptions"
                  :key="item.id"
                  :label="item.vendorName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="平台/站点/店铺" :class="$i18n.locale">
              <el-cascader
                v-model="form.platformSiteShop"
                clearable
                collapse-tags
                filterable
                :options="alldatas"
                class="style_w100"
                :props="{
                  value: 'value',
                  label: 'dropDownName',
                  children: 'dropDownList',
                  multiple: true,
                }"
              />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="类别" :class="$i18n.locale">
              <el-cascader
                v-model="form.CategoryIdList"
                separator=">"
                :filter-method="dataFilter"
                clearable
                collapse-tags
                filterable
                :options="basecategorydatas"
                class="style_w100"
                :props="{
                  value: 'id',
                  label: 'categoryName',
                  children: 'sonCategory',
                  multiple: true,
                }"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <el-row class="el-rt" style="width: 3%; text-align: right">
        <el-button type="text" @click="showRow = !showRow">{{
          showRow ? $t("page.hide") : $t("page.expand")
        }}</el-button>
      </el-row>
      <div class="el-rt header_btn">
        <div>
          <el-button
            v-show="lastVersionCode == form.versionCode"
            v-permission="'Toreplenish'"
            type="primary"
            class="ml-4"
            :loading="loading"
            @click="onSubmit"
          >
            <span v-show="activeName=== '1'">海外调拨补货</span>
            <span v-show="activeName=== '5'">国内快船补货</span>
            <span v-show="activeName=== '2'">国内普船补货</span>
          </el-button>
        </div>

        <div class="row-center">
          <el-form-item label="补货周期" class="header_replenishCycle" prop="replenishCycle">
            <el-input
              v-model="form.replenishCycle"
              oninput="value=value.replace(/[^0-9]/g,'')"
              :disabled="lastVersionCode !== form.versionCode"
              @blur="replenishmentCycle"
            />
          </el-form-item>
          <el-button
            v-permission="'exportA'"
            type="primary"
            class="ml-4"
            :loading="loading"
            @click="queryClick(1)"
          >{{ $t("page.search") }}</el-button>
          <el-button @click="resetClick()">{{ $t("page.reset") }}</el-button>
          <el-button
            type="primary"
            class="ml-4"
            :loading="exportLoading"
            @click="_exportDetail"
          >导出</el-button>
        </div>
      </div>
    </el-form>

    <Table
      ref="multipleTable"
      class="table_content"
      :show-expand-check="true"
      :table-data="tableDatas"
      max-height="600px"
      :columns="showColumn"
      :page-obj="pager"
      :check="true"
      expand
      expand-data="childVoList"
      :expand-columns="subColumns"
      :loading="tableLoading"
      @select="handleSelect"
      @selectExpandData="handleExpandSelect"
      @handleTableExpandChange="handleSelectionChange"
      @handleSelectionChange="clickChange"
      @selectAll="handleSelectAll"
    >
      <el-table-column slot="shopName" label="平台/站点/店铺" width="140">
        <template slot-scope="scope">
          <span>{{ scope.row.platformName }}/{{ scope.row.siteCode }}/{{ scope.row.shopName }}</span>
        </template>
      </el-table-column>

      <el-table-column slot="categoryNameI18" label="类别" width="140">
        <template slot-scope="scope">
          <span>{{ Array.isArray(scope.row.categoryNameI18) && scope.row.categoryNameI18.join('>') }}</span>
        </template>
      </el-table-column>
      <el-table-column slot="operate" label="操作" width="100">
        <template slot-scope="scope">
          <el-button
            v-if="lastVersionCode == form.versionCode"
            :loading="loading"
            type="text"
            size="small"
            @click="toModifyDetail(scope.row)"
          >
            补货
          </el-button>
        </template>
      </el-table-column>
    </Table>
    <div class="block">
      <Paging :pager="pager" end :page-sizes="pageSizes" @pagination="_purchasePage" />
    </div>
  </div>
</template>
<script>
import { getBasecategory } from '@/api/configuration'
import {
  queryStyleList,
  querySizeList,
  queryColorList,
  getplatformSiteShop,
  getDsbVersion,
  queryVendorList,
  StockChannelList,
  getProduceCycle
} from '@/api/scm-api'

import Table from '@/components/Table'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { queryBrandList } from '@/api/listSelection'
import { getListByPage, exportReplenish, postReplenish } from '@/api/supply'

import Paging from '@/components/Pagination'
import Select from '@/components/Selection'
import { getColumns, getSubColumns } from './coumns'
import { handelplatSiteShop, preText } from '@/utils'
import { omit } from 'lodash'
import { trackBtnClickEvent,
  trackSiteSearch, handleBreadTitle,
  trackExportEvent, trackPaginationEvent } from '@/utils/monitor'
import { handleMonitorParams } from '@/utils'

export default {
  components: {
    Paging,
    Table,
    Select
  },
  mixins: [commodityInfoDict],
  data() {
    return {
      pageSizes: [10, 20, 30, 40, 50],
      // showColumn: getColumns(),
      // subColumns: getSubColumns(this.activeName),
      alldatas: [],
      basecategorydatas: [],
      lastVersionCode: '',
      brandOptions: [],
      outMultipleSelection: [],
      styleOptions: [],
      colorOptions: [],
      sizeOptions: [],
      vendorOptions: [],
      StockChannelOptions: [],
      dsbVersionOptions: [],
      currentFactor: '',
      form: {
        versionCode: '', // 版本编号
        stockChannelIdList: [], // 备货渠道
        styleIdList: '',
        colorList: [],
        sizeList: [],
        brandIdList: [],
        skuList: '',
        vendorIdList: [],
        seasonIdList: [],
        positionIdList: [],
        CategoryIdList: [],
        replenishCycle: 14,
        platformSiteShop: ''
      },
      originalValue: 14,
      showRow: false,

      tableLoading: false,
      loading: false,
      exportLoading: false,
      activeName: '1',
      rules: {
        versionCode: [{ required: true, message: '请选择', trigger: 'change' }],
        replenishCycle: [{ required: true, message: '请选择', trigger: 'change' }]

      },
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      tableDatas: [],
      multipleSelection: []
    }
  },
  computed: {
    showColumn() {
      return getColumns(this.activeName)
    },
    subColumns() {
      return getSubColumns(this.activeName)
    },
    queryParams() {
      const {
        CategoryIdList, platformSiteShop, styleIdList, skuList } = this.form
      const lastCategoryIdList = []
      if (Array.isArray(CategoryIdList) && CategoryIdList.length > 0) {
        CategoryIdList.map(v => {
          v[v.length - 1] && lastCategoryIdList.push(v[v.length - 1])
        })
      }
      const { activeName } = this

      const params = {
        ...this.form,
        type: activeName,
        lastCategoryIdList,
        styleIdList: styleIdList ? [styleIdList] : [],
        platSiteShopList: handelplatSiteShop(platformSiteShop),
        skuList: preText(skuList, true)
      }
      return omit(params, ['platformSiteShop', 'CategoryIdList'])
    }
  },
  mounted() {
    this.activeName = this.$store.getters.replenishmentActiveName || '1'
    const defaultForm = this.$store.getters[`replenishment`] || {}
    this.form = Object.assign({}, this.$options.data.call(this).form, defaultForm)
    this._queryStyleList()
    this._brandList()
    this._listPlatformSiteShop()
    this._getBasecategory()
    this._getDsbVersion()
    this._queryColorList(this.form.styleIdList)
    this._querySizeList(this.form.styleIdList)
    this._queryVendorList()
    this._StockChannelList()
  },
  methods: {
    dataFilter(node, val) {
      if (!!~node.text.indexOf(val) || !!~node.text.toUpperCase().indexOf(val.toUpperCase())) {
        return true
      }
    },
    // 勾选主列表
    handleSelect({ rows, row }) {
      // 默认选中 可调配数据
      const checked = rows.some(item => item.id === row.id)
      this.tableDatas.map((item, index) => {
        if (item.id === row.id) {
          this.$set(item, 'checked', checked)
          item.childVoList.map((e) => {
            this.$set(e, 'checked', checked)
            this.$nextTick(() => {
              this.$refs.multipleTable.$refs['tableChild' + row.id ] && this.$refs.multipleTable.$refs['tableChild' + row.id ].toggleRowSelection(
                e,
                checked
              )
            })
          })
        }
      })
    },
    handleExpandSelect({ row, rows }) {
      this.tableDatas.map(item => {
        if (item.childVoList.some(ee => ee.id === row.id)) {
          item.childVoList.map(v => {
            Object.assign(v, { checked: rows.some(ee => ee.id === v.id) })
          })
        }
        const isChecked = item.childVoList.every(v => v.checked)
        this.$refs.multipleTable.$refs.table.toggleRowSelection(item, isChecked)
        Object.assign(item, { checked: isChecked })
      }
      )
    },
    clickChange(data) {
      this.outMultipleSelection = data
    },
    // 全选
    handleSelectAll(rows) {
      this.tableDatas.map((item) => {
        const checked = rows.some(v => v.id === item.id)
        this.$set(item, 'checked', checked)
        item.childVoList.map((e) => {
          this.$set(e, 'checked', checked)
          this.$nextTick(() => {
            this.$refs.multipleTable.$refs['tableChild' + item.id ] && this.$refs.multipleTable.$refs['tableChild' + item.id ].toggleRowSelection(
              e,
              checked
            )
          })
        })
      })
    },
    async _StockChannelList() {
      const { datas } = await StockChannelList('')
      this.StockChannelOptions = datas
    },
    handleSelectionChange(row) {
      Array.isArray(row.childVoList) && row.childVoList.map((e) => {
        if (e.checked) {
          this.$nextTick(() => {
            this.$refs.multipleTable.$refs['tableChild' + row.id ] && this.$refs.multipleTable.$refs['tableChild' + row.id ].toggleRowSelection(
              e,
              true
            )
          })
        }
      })
    },
    cycleExport() {
      const data = []
      this.tableDatas.map(list => {
        if (list.checked && Array.isArray(list.childVoList)) {
          list.childVoList.map(v => data.push(v.id))
        } else {
          Array.isArray(list.childVoList) && list.childVoList.map(v => {
            if (v.checked) {
              data.push(v.id)
            }
          })
        }
      })

      return data
    },
    async onSubmit() {
      const data = []
      const arr = []
      const stockChannelList = []
      this.tableDatas.map(list => {
        if (list.checked) {
          data.push(list)
        }
        Array.isArray(list.childVoList) && list.childVoList.map(v => {
          if (v.checked) {
            arr.push(v)
            if (!stockChannelList.includes(list.stockChannelId)) {
              stockChannelList.push(list.stockChannelId)
            }
          }
        })
      })
      let params = []

      if ((!data.length && !arr.length) || stockChannelList.length > 1) {
        return this.$message.warning('请仅选择同一备货渠道数据进行操作！')
      } else if (arr.length) {
        params = arr
      }
      this.replenishment(params)
    },
    async replenishment(params) {
      if (params.length) {
        params.map(v => Object.assign(v, { transferTypeDict: this.activeName }))
        try {
          this.loading = true
          const { datas } = await postReplenish(params)

          this.$store.dispatch('pages/setReplenishmentData', {
            result: JSON.stringify(datas),
            transferTypeDict: this.activeName,
            params: JSON.stringify(params)
          })

          this.$router.push({ path: 'confirmation', append: true, query: {
            type: this.activeName
          }})
          trackBtnClickEvent(handleBreadTitle(this.$route) + `${this.returnType(this.activeName)}补货按钮操作次数`,)
          this.loading = false
        } finally {
          this.loading = false
        }
      }
    },
    toModifyDetail(row) {
      // console.log("%c Line:525 🍢 row", "color:#f5ce50", row);
      const { childVoList } = row
      this.replenishment(childVoList)
    },
    sendModifyDetail(row) {
      this.handleSelectionChange([row])
      this.onSubmit()
    },
    // 获取style数据
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    handleStyleChange(val) {
      Object.assign(this.form, { colorList: [], sizeList: [] })
      this._queryColorList(val)
      this._querySizeList(val)
    },
    // color
    async _queryColorList(id) {
      const { datas } = await queryColorList({ styleId: id })
      this.colorOptions = datas
    },
    // size
    async _querySizeList(id) {
      const { datas } = await querySizeList({ styleId: id })
      this.sizeOptions = datas
    },
    // brand
    async _brandList() {
      const { datas } = await queryBrandList()
      this.brandOptions = datas
    },
    // 销售预测版本下拉
    async _getDsbVersion() {
      const { datas } = await getDsbVersion()
      if (!Array.isArray(datas)) return
      this.dsbVersionOptions = datas
      const lastVersionCode = datas[datas.length - 1].versionCode
      this.form.versionCode = lastVersionCode
      this.lastVersionCode = lastVersionCode
      this.queryClick(1)
    },
    // 供应商
    async _queryVendorList() {
      const { datas } = await queryVendorList()
      this.vendorOptions = datas
    },
    async getDomesticNum() {
    // 快船调接口，普船为正常
      const { datas } = await getProduceCycle()
      this.form.replenishCycle = datas
      this.originalValue = datas
    },

    handleDynamicList(records = []) {
      return records.map(record => {
        const updatedChildVoList = this.handleDynamicList(record.childVoList)

        Object.keys(record.availableQty).forEach(warehouseCode => {
          const index = record.warehouseCodeList.findIndex(code => code === warehouseCode)
          const warehouseLabel = record.warehouseNameList[index]

          record.availableQty[warehouseLabel] = record.availableQty[warehouseCode]

          if (warehouseCode !== warehouseLabel) {
            delete record.availableQty[warehouseCode]
          }
        })

        return { ...record, childVoList: updatedChildVoList }
      })
    },

    queryClick(flag) {
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
          try {
            this.$store.commit('saveForm/SAVE_REPLENISHMENT', { data: this.form, currentTab: this.activeName })

            this.loading = true
            this.tableLoading = true

            flag && flag === 1 ? (this.pager.current = 1) : ''

            const {
              datas: { pager, records }
            } = await getListByPage(this.queryParams, this.pager)
            this.pager = pager
            this.tableDatas = this.handleDynamicList(records)
            this.loading = false
            this.tableLoading = false
            trackSiteSearch(
              JSON.stringify(handleMonitorParams(this.queryParams)),
              handleBreadTitle(this.$route) + `${this.returnType(this.activeName)}`,
              pager.size
            )
          } catch (err) {
            console.log(err)
          } finally {
            this.loading = false
            this.tableLoading = false
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    changeVersion(value) {
      const { lastVersionCode } = this
      if (value !== lastVersionCode) {
        this.form.replenishCycle = 14
      }
    },
    async _exportDetail(query) {
      const idList = this.cycleExport()
      const params = {
        ...this.queryParams,
        idList
      }
      const { msg, code } = await exportReplenish({ params: JSON.stringify(params), exportKey: 'scm_dsb_export' })
      if (code === 0) {
        this.$notify({
          message: msg,
          type: 'success'
        })
      } else {
        this.$notify({
          title: msg,
          message: msg,
          type: 'success'
        })
      }
      trackExportEvent(handleBreadTitle(this.$route) + `${this.returnType(activeName)}`)
    },
    returnType(activeName) {
      switch (activeName) {
        case '1':
          return '海外调拨补货'
        case '5':
          return '国内快船补货'
        case '2':
          return '国内普船补货'
        default:
          return ''
      }
    },
    async resetClick() {
      this.form = this.$options.data.call(this).form
      this.form.versionCode = this.lastVersionCode
      await this.initOriginalValue()
      this.queryClick(1)
    },

    async _listPlatformSiteShop() {
      const { datas } = await getplatformSiteShop()
      this.getDataTree(datas)
      this.alldatas = datas
    },
    async _getBasecategory() {
      const { datas } = await getBasecategory()
      // this.getDataTree(datas);
      this.basecategorydatas = datas
    },
    async handleClick(tab, event) {
      this.form.versionCode = this.lastVersionCode
      await this.initOriginalValue()
      this.queryClick(1)
    },
    async initOriginalValue() {
      if (this.activeName != '5') {
        this.form.replenishCycle = 14
        this.originalValue = 14
      } else {
        await this.getDomesticNum()
      }
    },
    async _purchasePage(pagers) {
      this.pager = pagers
      this.queryClick()
      trackPaginationEvent(
        handleBreadTitle(this.$route) + `${this.returnType(this.activeName)}-条目数${pagers.size}-当前页${pagers.current}`,
      )
    },
    getDataTree(data) {
      if (!Array.isArray(data)) return
      for (let i = 0; i < data.length; i++) {
        data[i].value = `${data[i].dropDownId}&&${data[i].dropDownName}`
        this.getDataTree(data[i].dropDownList)
      }
    },
    replenishmentCycle() {
      if (this.form.replenishCycle < 7) {
        this.form.replenishCycle = 7
      }
      if (this.form.replenishCycle > 60) {
        this.form.replenishCycle = 60
      }
      if (this.originalValue != this.form.replenishCycle) {
        this.$confirm(`将修改补货周期为${this.form.replenishCycle}天，
          并重新生成补货建议，是否继续？确认or取消，
          确认后依据补货周期生成补货建议数据（仅对当前用户生效）?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          this.originalValue = this.form.replenishCycle
          this.queryClick(1)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          // 埋点
          trackBtnClickEvent(handleBreadTitle(this.$route) + `${this.returnType(this.activeName)}修改补货周期次数`,)
        }).catch(() => {
          this.form.replenishCycle = this.originalValue
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
      }
    }
  }
}
</script>
<style lang="scss" scope>

.style_w100 {
  width: 100%;
}
.header_btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.header_replenishCycle {
  margin-bottom: 0
}

// .table_content {
//  .el-table__header-wrapper {
//     .el-checkbox {
//       // display: none;//设置不成功，页面卡顿
//       visibility: hidden;
//     }
//   }
// }
</style>
