function getColumns(type) {
  return [
    {
      prop: 'stockChannelName',
      label: '备货渠道',
      width: '150'
    },
    {
      slot: 'shopName',
      label: '平台/站点/店铺',
      width: '150'
    },
    {
      prop: 'styleName',
      label: 'Style',
      width: '120'
    },
    {
      slot: 'categoryNameI18',
      label: '类别',
      width: '120'
    },
    {
      prop: 'brandName',
      label: '品牌',
      width: '120'
    },
    {
      prop: 'seasonName',
      label: '季节',
      width: '120'
    },
    {
      prop: 'positionName',
      label: '定位',
      width: '120'
    },
    {
      prop: 'negativeCashFlag',
      label: '是否负现金流',
      width: '120'
    },
    {
      prop: 'vendorName', //
      label: '供应商',
      width: '120'
    },
    {
      prop: 'turnOver',
      label: '补货TurnOver（天）',
      width: '120'
    },
    {
      prop: 'gapQuantity',
      label: '补货缺口',
      width: '120'
    },
    {
      prop: 'totalNetShippingDemand', //
      label: '补货需求',
      width: '120'
    },
    {
      prop: 'versionCode',
      label: '销售预测版本',
      width: '120'
    },
    {
      prop: 'totalPredictSales',
      label: '预测销量', //
      width: '120'
    },
    {
      prop: 'totalReturn',
      label: '预测退货', //
      width: '120'
    },
    {
      prop: 'platAvailableStock',
      label: '平台仓可用库存(期初)',
      width: '120'

    },
    {
      prop: 'platShippingStock',
      label: '平台仓在途库存(期初)',
      width: '120'

    },
    {
      prop: 'overSeaOwnAvailableStock',
      label: '海外自管仓可用库存（期初）',
      width: '120',
      show: type !== '1'

    },
    {
      prop: 'overSeaOwnShippingStock',
      label: '海外自管仓在途库存（期初）',
      width: '120',
      show: type !== '1'

    },
    {
      prop: 'overSeaOtherAvailableStock',
      label: '海外三方仓可用库存（期初）',
      width: '120',
      show: type !== '1'

    },
    {
      prop: 'overSeaOtherShippingStock',
      label: '海外三方仓在途库存（期初）',
      width: '120',
      show: type !== '1'
    },
    {
      prop: 'unclearStock',
      label: '国内仓调拨未发&未结（期初）',
      show: type !== '1'

    },
    {
      prop: 'overSeaUnclearStock',
      label: '海外仓调拨未发&未结（期初）'
    },
    {
      prop: 'platAvailableStockBf',
      label: '平台仓可用库存（到货前）'

    },

    {
      prop: 'platShippingStockBf',
      label: '平台仓在途库存（到货前）'
    },
    {
      prop: 'overSeaOwnAvailableStockBf',
      label: '海外自管仓可用库存（到货前）',
      show: type !== '1'

    },

    {
      prop: 'overSeaOwnShippingStockBf',
      label: '海外自管仓在途库存（到货前）',
      show: type !== '1'

    },
    {
      prop: 'overSeaOtherAvailableStockBf',
      label: '海外三方仓可用库存（到货前）',
      show: type !== '1'

    },

    {
      prop: 'overSeaOtherShippingStockBf',
      label: '海外三方仓在途库存（到货前）',
      show: type !== '1'

    },
    {
      prop: 'unclearStockBf',
      label: '国内仓调拨未发&未结（到货前）',
      show: type !== '1'

    },
    {
      prop: 'overSeaUnclearStockBf',
      label: '海外仓调拨未发&未结（到货前）'
    },
    {
      prop: 'rps',
      label: 'RPS',
      width: '120'
    },
    {
      prop: 'recentSale',
      label: '近30天销量',
      width: '120'
    },
    {
      prop: 'recentReturn',
      label: '近30天退货',
      width: '120'
    },
    {
      prop: 'mps',
      label: 'MPS',
      width: '120'
    },
    {
      prop: 'safeStockDays',
      label: '安全库存天数',
      width: '120'
    },

    {
      prop: 'availableQty',
      label: '',
      width: '120',
      dynamic: true,
      show: false,
      dynamicValue: 'availableQty',
      columnLabelList: 'warehouseNameList'

    },
    {
      slot: 'operate',
      label: '操作',
      width: '100'
    }
  ]
}

function getSubColumns(type) {
  return [
    {
      prop: 'color',
      label: 'Color'

    },
    {
      prop: 'size',
      label: 'Size'

    },
    {
      prop: 'skuName',
      label: 'SKU',
      show: true

    },
    {
      prop: 'positionName',
      label: '定位',
      width: '120'
    },
    {
      prop: 'negativeCashFlag',
      label: '是否负现金流',
      width: '120'
    },
    { // cnFastReplTurnover （国内快船） cnGenReplTurnover （国内普船）  // overseasReplTurnover （海外调拨）
      prop: 'turnOver', //
      label: '补货TurnOver（天）'
    },
    {
      prop: 'gapQuantity',
      label: '补货缺口',
      width: '120'
    },
    {
      prop: 'totalNetShippingDemand', //
      label: '补货需求'
    },
    {
      prop: 'versionCode',
      label: '销售预测版本'

    },
    {
      prop: 'totalPredictSales',
      label: '预测销量' //

    },
    {
      prop: 'totalReturn',
      label: '预测退货'

    },
    {
      prop: 'platAvailableStock',
      label: '平台仓可用库存(期初)'
    },
    {
      prop: 'platShippingStock',
      label: '平台仓在途库存（期初）'

    },
    {
      prop: 'overSeaOwnAvailableStock',
      label: '海外自管仓可用库存(期初)',
      show: type !== '1'

    },
    {
      prop: 'overSeaOwnShippingStock',
      label: '海外自管仓在途库存（期初）',
      show: type !== '1'
    },
    {
      prop: 'overSeaOtherAvailableStock',
      label: '海外三方仓可用库存（期初）',
      show: type !== '1'
    },
    {
      prop: 'overSeaOtherShippingStock',
      label: '海外三方仓在途库存（期初）',
      show: type !== '1'
    },
    {
      prop: 'unclearStock',
      label: '国内仓调拨未发&未结（期初）',
      show: type !== '1'

    },
    {
      prop: 'overSeaUnclearStock',
      label: '海外仓调拨未发&未结（期初）'
    },
    {
      prop: 'platAvailableStockBf',
      label: '平台仓可用库存（到货前）'

    },
    {
      prop: 'platShippingStockBf',
      label: '平台仓在途库存（到货前）'

    },
    {
      prop: 'overSeaOwnAvailableStockBf',
      label: '海外自管仓可用库存（到货前）',
      show: type !== '1'

    },

    {
      prop: 'overSeaOwnShippingStockBf',
      label: '海外自管仓在途库存（到货前）',
      show: type !== '1'

    },
    {
      prop: 'overSeaOtherAvailableStockBf',
      label: '海外三方仓可用库存（到货前）',
      show: type !== '1'

    },

    {
      prop: 'overSeaOtherShippingStockBf',
      label: '海外三方仓在途库存（到货前）',
      show: type !== '1'

    },
    {
      prop: 'unclearStockBf',
      label: '国内仓调拨未发&未结（到货前）',
      show: type !== '1'

    },
    {
      prop: 'overSeaUnclearStockBf',
      label: '海外仓调拨未发&未结（到货前）'

    },

    {
      prop: 'rps',
      label: 'RPS'

    },
    {
      prop: 'recentSale',
      label: '近30天销量'

    },
    {
      prop: 'recentReturn',
      label: '近30天退货'

    },
    {
      prop: 'mps',
      label: 'MPS'

    },
    {
      prop: 'safeStockDays',
      label: '安全库存天数'

    },

    {
      prop: 'availableQty',
      label: '',
      width: '120',
      dynamic: true,
      show: false,
      dynamicValue: 'availableQty',
      columnLabelList: 'warehouseNameList'

    }
    // {
    //   slot: 'suboperate',
    //   label: '操作',
    //   width: '100',
    // },
  ]
}

export { getColumns, getSubColumns }
